// Dependency
import React, { useEffect, useState } from "react"
import CheckoutCart from "../components/checkout/checkout-cart/checkout-cart"

// Components
import { useTranslation } from "react-i18next"

import PaymentProvider from "../components/checkout/payment-provider/payment-provider"
import Ingrid from "../components/checkout/ingrid/ingrid"
import GA4 from "../services/tracking"
import { useBrink } from "../context/BrinkContext"
import "../components/checkout/checkout.scss"

// Internationalization

const Checkout = ({ location }) => {
  const { t } = useTranslation()
  const [isShippingValid, setIsShippingValid] = useState(false)
  const { cart, addToCart, BrinkSessionId } = useBrink()


  const getCart = async token =>
    await addToCart({ productVariant: false, quantity: 0, token })

  const useMountEffect = fun => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(fun, [])
  }

  useMountEffect(() => {
    let storedCart = cart
    const startCheckout = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get("cart")
      if (token) {
        storedCart = await getCart(token)
      }
    }
    startCheckout()

    if (storedCart.cartItems.length > 0) {
      const items = storedCart.cartItems.filter(
        item => item.type === "productVariant"
      )
      GA4.beginCheckout(items, storedCart, BrinkSessionId)
    }
  })

  return (
    <section>
      <div className="checkout">
        <div className="checkout__left">
          <div className="checkout__container">
            <div className="checkout__heading">
              <span className="hide-desktop">2/3 </span>
              {t("checkout.deliveryoptions")}
            </div>
            <Ingrid setIsShippingValid={setIsShippingValid} />
            {isShippingValid && (
              <div className="checkout__payment">
                <div className="checkout__heading">
                  <span className="hide-desktop">3/3 </span>
                  {t("checkout.paymentOptions")}
                </div>
                <div className="checkout__payment__provider">
                  <PaymentProvider
                    isShippingValid={isShippingValid}
                    provider={"klarna"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className=" checkout__right">
          <div className="checkout__container">
            <CheckoutCart cart={cart} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Checkout

export const Head = () => <title>Tanrevel | Checkout</title>
