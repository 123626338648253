import React, { useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useBrink } from "../../../context/BrinkContext"
import GA4 from "../../../services/tracking"
import Loader from "../../ui/loader/loader"

const KlarnaCheckout = ({ snippet, cart }) => {
  const useMountEffect = fun =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])
  const kcoRef = useRef(null)

  useMountEffect(() => {
    const scriptsTags = kcoRef.current.getElementsByTagName("script")
    // This is necessary otherwise the scripts tags are not going to be evaluated
    for (let i = 0; i < scriptsTags.length; i++) {
      const parentNode = scriptsTags[i].parentNode
      const newScriptTag = document.createElement("script")
      newScriptTag.type = "text/javascript"
      newScriptTag.text = scriptsTags[i].text
      parentNode.removeChild(scriptsTags[i])
      parentNode.appendChild(newScriptTag)
    }

    window._klarnaCheckout(api => {
      api.on({
        load() {
          console.log("_klarnaCheckout:load")
          GA4.addPaymentInfo({
            payment_method: "klarna",
            value: cart.totalPrice,
            currency: cart.store.currencyUnit,
            cart
          })
          console.log("_klarnaCheckout:tracked")
        },
        redirect_initiated() {
          console.log("_klarnaCheckout:redirect_initiated")
        },
      })
    })
  })

  return <div ref={kcoRef} dangerouslySetInnerHTML={{ __html: snippet }} />
}

const Klarna = ({ isShippingValid }) => {
  const { t } = useTranslation()
  const { cartToKlarnaOrder, cart } = useBrink()
  const [displayError, setDisplayError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [klarnaSnippet, setKlarnaSnippet] = useState(null)

  const isEmptyCart = cart =>
    cart.cartItems.filter(p => p.type === "productVariant").length === 0

  useEffect(() => {
    if (!isShippingValid || isEmptyCart(cart)) return

    const createKlarnaCheckout = async () => {
      setIsLoading(true)
      const klarnaOrder = await cartToKlarnaOrder({
        additional_checkbox: {
          text: t("klarna.newsletter"),
          checked: true,
          required: false,
        },
      })
      setIsLoading(false)
      !klarnaSnippet &&
        klarnaOrder &&
        setKlarnaSnippet(klarnaOrder.html_snippet)
    }

    window._klarnaCheckout && window._klarnaCheckout(api => api.suspend())

    createKlarnaCheckout()
      .catch(() => setDisplayError(true))
      .finally(() => {
        window._klarnaCheckout && window._klarnaCheckout(api => api.resume())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShippingValid, cart])

  return (
    <>
      {isLoading && <Loader />}
      {displayError && (
        <div>
          {t(
            "Something went wrong with your payment. Please try another option or contact our support."
          )}
        </div>
      )}
      {klarnaSnippet && <KlarnaCheckout snippet={klarnaSnippet} cart={cart} />}
    </>
  )
}

export default Klarna
