//Dependencies
import React, { useState } from "react"
import Price from "../../general/price/price"
import Button from "../../ui/button/button"
import Textfield from "../../general/form/textfield/textfield"
import cx from "classnames"
import SvgIcon from "../../../hooks/svg"

//Styles
import "./cart-content.scss"

//Components
import CartItem from "../cart-item/cart-item"
import CartUpsellCarousel from "../cart-upsell-carousel/cart-upsell-carousel"
import { useTranslation } from "react-i18next"
import { useBrink } from "../../../context/BrinkContext"
import RenderLink from "../../ui/link/link"

const CartContent = props => {
  const { t } = useTranslation()
  const { handleVoucher } = useBrink()
  const [discount, setDiscount] = useState()
  const isLoading = false
  const [errorMessage, setErrorMessage] = useState()

  const cartItems = props.cart.cartItems.filter(
    item => item.type !== "shippingOption"
  )

  const storeTax = props.cart.store.taxPercentage / 100

  const vat =
    ((props.cart.totalPriceWithDiscount / (storeTax + 1)) * storeTax) | 0

  const handleSubmit = async e => {
    e.preventDefault()
    const voucherCall = await handleVoucher({ code: discount.toUpperCase() })
    if (voucherCall === 0) {
      setErrorMessage(t("cart.discountCodeError"))
    }
  }

  const handleChange = e => {
    setDiscount(e.target.value.toUpperCase())
    setErrorMessage()
  }

  const handleRemove = async () => {
    await handleVoucher({ code: "deleteDiscount" })
    setDiscount()
  }

  const toggleTab = (tabTop, tabBottom) => {
    //Transform animation
    tabBottom?.offsetParent === null
      ? tabTop.classList.add("order-tabs__tab--open")
      : tabTop.classList.remove("order-tabs__tab--open")
    //Toggle visibillity
    if (tabBottom) {
      const displayValue =
        tabBottom?.offsetParent === null
          ? "display: flex; flex-direction: column; gap: 16px;"
          : "display: none;"
      tabBottom.setAttribute("style", displayValue)
    }
  }

  const renderHtml = (
    <div className="cart-content__voucher-form">
      <form onSubmit={handleSubmit}>
        <div className="cart-content__voucher-form__inner">
          <div className="cart-content__voucher-form__inner-left">
            <Textfield
              value={discount}
              defaultValue=""
              name="discount"
              label={t("cart.discountCode")}
              useStatic={true}
              onChange={handleChange}
              required={true}
              type="text"
              hideLabel={true}
              errorMessage={errorMessage}
            />
          </div>
          <div className="cart-content__voucher-form__inner-right">
            <Button
              buttonText={t("button.add")}
              type="submit"
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  )

  const renderVouchers = code => {
    return (
      <div className="cart-content__vouchers">
        <div className="cart-content__voucher">
          <div className="cart-content__voucher-code">{code}</div>
          <div className="cart-content__voucher-remove">
            <Button
              onClick={handleRemove}
              buttonText={t("cart.remove")}
              transparetBg={true}
            />
          </div>
        </div>
      </div>
    )
  }

  const getDiscountCode = () => {
    const rule = props.cart?.discounts?.rules?.find(
      r => r.ruleType === "DISCOUNTCODE"
    )
    return rule?.ruleData?.discountCode ?? false
  }

  return (
    <div
      className={cx("cart-content", {
        "cart-content--checkout": props.checkout,
      })}
    >
      <div className="order-tabs__tab">
        <div
          className="order-tabs__tab-header order-tabs__tab--open"
          onClick={e => {
            toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
          }}
        >
          <div className="order-tabs__tab-header--left">
            <p>
              <span className="hide-desktop">1/3 </span>
              {t("cart.yourCart")} ({cartItems && cartItems.length}{" "}
              {t("cart.items")})
            </p>
          </div>
          <div className="order-tabs__tab__arrow">
            <SvgIcon name="keyboard_arrow_down" />
          </div>
        </div>
        <div className="order-tabs__tab-content">
          <div
            className={cx("cart-content__content", {
              "cart-content__content--checkout": props.checkout,
            })}
          >
            <CartItem cartItems={cartItems} controls={true} />
          </div>
          {cartItems.length === 0 && (
            <div className="cart-content__empty-heading">{t("cart.empty")}</div>
          )}
        </div>
      </div>

      {cartItems.length !== 0 && props.cartUpsellCarousel && (
        <div className="cart-content__upsell__carousel">
          <CartUpsellCarousel props={props.cartUpsellCarousel} />
        </div>
      )}

      <div className="order-tabs__tab">
        <div
          className="order-tabs__tab-header"
          onClick={e => {
            toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
          }}
        >
          <div className="order-tabs__tab-header--left">
            <p>{t("cart.discountCode")}</p>
          </div>
          <div className="order-tabs__tab__arrow">
            <SvgIcon name="keyboard_arrow_down" />
          </div>
        </div>
        <div className="order-tabs__tab-content" style={{ display: "none" }}>
          {getDiscountCode() ? renderVouchers(getDiscountCode()) : renderHtml}
        </div>
      </div>

      <div className="order-tabs__tab">
        <div
          className="order-tabs__tab-header order-tabs__tab--open"
          onClick={e => {
            toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
          }}
        >
          <div className="order-tabs__tab-header--left">
            <p> {t("cart.summation")}</p>
          </div>
          <div className="order-tabs__tab__arrow">
            <SvgIcon name="keyboard_arrow_down" />
          </div>
        </div>
        <div className="order-tabs__tab-content">
          {cartItems.length !== 0 && (
            <div className="cart-content--wrapper">
              <div className="cart-content__summary">
                <div className="cart-content__summary-line">
                  {t("cart.price")}
                  <span>
                    <Price
                      prices={[
                        {
                          amount: props.cart.totalPrice,
                          currencyUnit: props.currencyUnit,
                        },
                      ]}
                    />
                  </span>
                </div>
                <div className="cart-content__summary-line">
                  {t("cart.shipping")}
                  <span>
                    <Price
                      prices={[
                        {
                          amount: props.cart.totalShippingAmount,
                          currencyUnit: props.currencyUnit,
                        },
                      ]}
                    />
                  </span>
                </div>
                <div className="cart-content__summary-line">
                  {t("cart.vat")}
                  <span>
                    <Price
                      prices={[
                        {
                          amount: vat,
                          currencyUnit: props.currencyUnit,
                        },
                      ]}
                    />
                  </span>
                </div>
                {props.cart.totalDiscountAmount !== 0 && (
                  <div className="cart-content__summary-line cart-content__summary-line--discount">
                    {t("cart.discount")}
                    <span>
                      <Price
                        prices={[
                          {
                            amount: props.cart.totalDiscountAmount,
                            currencyUnit: props.currencyUnit,
                          },
                        ]}
                      />
                    </span>
                  </div>
                )}
                <div className="cart-content__sub-total">
                  {t("cart.subtotal")}
                  <span>
                    <Price
                      prices={[
                        {
                          amount: props.cart.totalPriceWithDiscount,
                          currencyUnit: props.currencyUnit,
                        },
                      ]}
                    />
                  </span>
                </div>
              </div>
              <div className="cart__checkout-button">
                {cartItems.length > 0 && (
                  <RenderLink
                    asset="arrow-right-white"
                    linkText={t("cart.checkout")}
                    linkUrl="/checkout"
                    buttonLook="green"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CartContent
