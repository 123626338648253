import React, { useRef, useEffect, useState } from 'react'
import { useBrink } from '../../../context/BrinkContext'
import { isEqual } from "lodash"
import useLocalStorage from '../../../hooks/useLocalStorage'

const Ingrid = ({ setIsShippingValid }) => {
    const {
        syncIngridDeliveryCheckout,
        cart,
    } = useBrink()

    const [init, setInit] = useState(false)
    const [test, setTest] = useState(false)
    const [shippingAddress, updateShippingAddress] = useLocalStorage("address", {})
    const requireAddress = useRef(false)
    const widgetRef = useRef(null)
    const useMountEffect = (fun) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(fun, [])
    }
    const firstUpdate = useRef(true)

    const useEffectWhen = (effect, deps, whenDeps) => {
        const whenRef = useRef(whenDeps || [])
        const initial = whenRef.current === whenDeps
        const whenDepsChanged =
            initial || !whenRef.current.every((w, i) => isEqual(w, whenDeps[i]))
        whenRef.current = whenDeps
        const nullDeps = deps.map(() => null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useEffect(
            whenDepsChanged ? effect : () => { },
            whenDepsChanged ? deps : nullDeps
        )
    }

    useMountEffect(() => {
        const mount = async () => {
            const ingrid = await syncIngridDeliveryCheckout(shippingAddress)
            if (ingrid && widgetRef.current) {
                widgetRef.current.innerHTML = ingrid.htmlSnippet
                const displayUpfrontAddressMatch = ingrid.htmlSnippet.match(
                    /"display_upfront_address":(\w*)/
                )
                if (displayUpfrontAddressMatch?.length > 0) {
                    const shouldRequireAddress = /true/i.test(
                        displayUpfrontAddressMatch[1]
                    )
                    requireAddress.current = shouldRequireAddress
                }
                replaceScriptNode(document.getElementById("shipwallet-container"))
                setupListeners()
            }
        }
        mount()
    })

    useEffectWhen(
        () => {
            if (!init) return
            if (window._sw) {
                window._sw((api) => api.suspend())
            }
            syncIngridDeliveryCheckout(shippingAddress).then(() => {
                setInit(true)
                if (shippingAddress.postalCode) {
                    console.log("ingrid:shipping is valid");
                    setIsShippingValid(true)
                }
                window._sw((api) => api.resume())
            })
        },
        [cart.cartItems, cart.discounts, init, setInit],
        [cart.cartItems, cart.discounts, test]
    )

    const onLoaded = () => {
        console.log("ingrid:loaded")
        setInit(true)
    }

    const onShippingOptionChanged = (option) => {
        if (firstUpdate.current && requireAddress.current) {
            firstUpdate.current = false
            return
        }
        console.log("ingrid:shipping_option_changed", option)
        //setIsLoading(true)
        return setTimeout(() => {
            syncIngridDeliveryCheckout(shippingAddress, false).then(() => {
                if (shippingAddress.postalCode || !requireAddress.current) {
                    setIsShippingValid(true)
                }
            })
        }, 100)
    }

    const onAddressChanged = (address) => {
        console.log("ingrid:address_changed", address)
        updateShippingAddress({
            postalCode: address.postal_code,
            country: address.country_code
        })
        if (address.postal_code) {
            setIsShippingValid(true)
            setTest(!test)
        }
    }

    const setupListeners = () => {
        window._sw((api) => {
            api.on("loaded", onLoaded)
            api.on("shipping_option_changed", onShippingOptionChanged)
            api.on("address_changed", onAddressChanged)
        })
    }

    return (
        <div ref={widgetRef} />
    )
}

const isScriptNode = (node) => node.tagName === "SCRIPT"

const isExternalScript = (node) => !!node.src && node.src !== ""

const cloneScriptNode = (node) => {
    const script = document.createElement("script")
    script.text = node.innerHTML
    for (let i = node.attributes.length - 1; i >= 0; i--) {
        script.setAttribute(node.attributes[i].name, node.attributes[i].value)
    }
    return script
}

const replaceScriptNode = (node) => {
    if (isScriptNode(node) && !isExternalScript(node)) {
        if (node.parentNode) {
            node.parentNode.replaceChild(cloneScriptNode(node), node)
        }
    } else {
        let i = 0
        const children = node.childNodes
        while (i < children.length) {
            replaceScriptNode(children[i++])
        }
    }
    return node
}

export default Ingrid