//Dependencies
import React from "react"
import { useTranslation } from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import CartContent from "../../cart/cart-content/cart-content"

//Styles
import "./checkout-cart.scss"

const CheckoutCart = props => {
  const { t } = useTranslation()
  // const {
  //   cart,
  //   store: { currencyUnit },
  // } = useBrink()
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "header" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          content
          full_slug
        }
      }
    }
  `)
  const filteredData =
    query.story.nodes.find(node =>
      node.full_slug.includes(process.env.GATSBY_BUILD_LANGUAGE)
    ) ?? query.story.nodes[0]
  const data = JSON.parse(filteredData.content)
  const carousel = data.blocks.find(i => i.component === "mini-cart")
    .cartContent[0]

  return (
    <div className="checkout-cart">
      <div className="checkout-cart__header">
        <div className="checkout-cart__heading">
          {t("checkout.shoppingCart")}
        </div>
      </div>
      <CartContent
        cart={props.cart}
        cartUpsellCarousel={carousel}
        currencyUnit={props.cart.store.currencyUnit}
        checkout={true}
      />
    </div>
  )
}

export default CheckoutCart
